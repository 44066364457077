import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	color: #151518 !important;
	padding: 8px 8px;
	font-size: 1rem;
	margin: 5px 10px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	box-shadow: 5px 5px 10px black;
	font-weight: 800;
	background-color: white;
	:hover {
		filter: brightness(0.8);
	}
`;

const Link = styled.a`
	width: 100%;
	height: 100%;
	color: #151518;
	text-decoration: none;
	font-size: 0.85rem;
	:hover {
		color: #151518 !important;
	}
`;

export const Button = ({ href, children }: { href: string; children: any }): JSX.Element => (
	<Wrapper>
		<Link href={href}>{children}</Link>
	</Wrapper>
);
