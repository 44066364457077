import * as React from 'react';
import darkmode from '@assortment/darkmodejs';
import { ThemeProvider as WebThemeProvider } from 'styled-components';
import { ThemeProvider as NativeThemeProvider } from 'styled-native-components';

import { Brand as BrandType, createTheme, Theme } from '@cinuru/utils/theme';
import { PortalProvider } from '@cinuru/components';

declare module 'styled-native-components' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	export interface DefaultTheme extends Theme {}
}

const ThemeProvider = ({
	children,
	brand = 'CINURU',
	ignoreOsTheme,
}: {
	children: React.ReactNode;
	brand?: BrandType;
	ignoreOsTheme: boolean;
}): JSX.Element => {
	const [darkMode, setDarkMode] = React.useState(true);

	React.useEffect(() => {
		if (ignoreOsTheme) {
			return;
		} else {
			darkmode({
				onChange: (osTheme) => {
					if (osTheme === 'dark') {
						setDarkMode(true);
					} else {
						setDarkMode(false);
					}
				},
			});
		}
	}, [ignoreOsTheme]);
	const theme = React.useMemo(() => {
		return createTheme(brand, { darkMode });
	}, [darkMode, brand]);
	console.log(theme);

	return (
		<NativeThemeProvider theme={theme} rootBackgroundColor="$background1">
			<WebThemeProvider theme={theme}>
				<PortalProvider>{children}</PortalProvider>
			</WebThemeProvider>
		</NativeThemeProvider>
	);
};

export default ThemeProvider;
