import React from 'react';
import styled from 'styled-native-components';

import { AppSection } from './HeaderSection';

/* ------------------------------ BannerSection ----------------------------- */
const BannerSectionWrapper = styled(AppSection)`
	margin-top: 5vw;
	position: relative;
	height: calc(150px + 10vw);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding-bottom: 0px;
`;

const BannerSmartphones = styled.View`
	overflow: visible;
	position: absolute;
	width: 100%;
	height: calc(100px + 10vw);
	max-height: 400px;

	background-image: url('../images/phones.png');
	background-position: center bottom;
	background-repeat: no-repeat;
	z-index: 1;
	background-size: calc(30rem + 40vw);
`;

const BannerSwoosh = styled.View`
	overflow: visible;
	position: absolute;
	width: 100%;
	max-width: 2600px;
	height: calc(150px + 15vw);
	background-image: url('../images/swoosh.svg');
	background-position: center bottom -2px;
	background-size: cover;
	z-index: 0;
	background-repeat: no-repeat;
`;

export const BannerSection = (): JSX.Element => (
	<BannerSectionWrapper>
		<BannerSmartphones />
		<BannerSwoosh />
	</BannerSectionWrapper>
);
