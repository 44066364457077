/* eslint-disable no-catch-all/no-catch-all */
import React from 'react';
import { useParams } from 'react-router-dom';

import styled, { useTheme } from 'styled-native-components';
import { default as gql } from 'graphql-tag';

import { Button, Heading, LoadingIndicator, StarRating, Paragraph } from '@cinuru/components';
import { Brand } from '@cinuru/utils/';

import { AppSection } from '../Components/HeaderSection';
import ThemeProvider from '../Components/ThemeProvider';
import { QuizError } from '../Components/QuizError';
import NavigationButton from '../Components/NavigationButton';
import { useApolloClient, useQuery } from '@apollo/client';

const ImageWrapper = styled.View`
	display: flex;
	flex-direction: row;
	min-width: 300px;
	max-width: 400px;
	max-height: 500px;
	width: 70vw;
	justify-content: center;
	align-items: center;
`;

const NavigationPlaceholder = styled.View`
	width: 70px;
`;

const useMoviesToRate = (amount: number, sentEmailId?: string) => {
	const { data, error } = useQuery(
		gql`
			query MoviesToRateToInitializeRecommender($amount: Int!, $sentEmailId: String!) {
				moviesToRateToInitializeRecommender(amount: $amount, sentEmailId: $sentEmailId) {
					title
					poster
					id
					releaseDate
					directedBy
				}
			}
		`,
		{ fetchPolicy: 'network-only', variables: { amount, sentEmailId: sentEmailId || '' } }
	);

	if (error?.message === 'Network error: Failed to fetch') {
		throw new Error(error.message);
	}

	return {
		error: error?.message,
		data: data?.moviesToRateToInitializeRecommender.map((movie) => {
			return {
				...movie,
				poster: !movie.poster
					? 'https://static.cinuru.com/public/imagesSmall/1562229930350.jpg'
					: movie.poster,
			};
		}),
	};
};

const InnerQuiz = (): JSX.Element => {
	const client = useApolloClient();
	const { brand = 'CINURU', sentEmailId }: { brand?: Brand; sentEmailId?: string } = useParams();

	const theme = useTheme();
	const { data: movies, error: fetchError } = useMoviesToRate(4, sentEmailId);
	const [reviewRatings, setReviewRatings] = React.useState({});
	const [moviePosition, setMoviePosition] = React.useState<number>(0);
	const [maxPosition, setMaxPosition] = React.useState<number>(0);
	const [error, setError] = React.useState(null);

	const handleRating = React.useCallback(
		async (value) => {
			const movieId = movies[moviePosition].id;

			setReviewRatings((b) => ({
				...b,
				[movieId]: reviewRatings[movieId] === value ? null : value,
			}));
			try {
				client.mutate({
					mutation: gql`
						mutation UpsertQuizReviewRating($sentEmailId: String!, $movieId: ID!, $value: Float) {
							upsertQuizReviewRating(sentEmailId: $sentEmailId, movieId: $movieId, value: $value) {
								reviewRating {
									id
									movie {
										id
									}
									value
									updatedAt
								}
							}
						}
					`,
					variables: {
						sentEmailId: sentEmailId,
						movieId,
						value: reviewRatings[movieId] === value ? null : value,
					},
				});
			} catch (e) {
				if (e?.message === 'Network error: Failed to fetch') {
					throw new Error(e.message);
				}
				setError(e.message);
			}
			setTimeout(() => {
				if (moviePosition === maxPosition) {
					setMaxPosition(maxPosition + 1);
				}
				setMoviePosition(moviePosition + 1);
			}, 500);
		},
		[
			reviewRatings,
			movies,
			moviePosition,
			maxPosition,
			sentEmailId,
			setMoviePosition,
			setMaxPosition,
			setError,
			client,
		]
	);

	const handleForward = React.useCallback(() => {
		setMoviePosition((b: number) => b + 1);
	}, [setMoviePosition]);
	const handleBackward = React.useCallback(() => {
		setMoviePosition((b: number) => b - 1);
	}, [setMoviePosition]);

	const { id, poster, directedBy, title, releaseDate } = React.useMemo(
		() => (movies && movies[moviePosition] ? movies[moviePosition] : { id: null, poster: null }),
		[movies, moviePosition]
	);

	return fetchError || error ? (
		<QuizError error={fetchError || error} />
	) : id ? (
		<AppSection height="100vh">
			<ImageWrapper>
				{moviePosition > 0 ? (
					<NavigationButton onClick={handleBackward} direction={'left'} />
				) : (
					<NavigationPlaceholder />
				)}
				<img
					id={id}
					src={poster}
					style={{
						width: 'calc(100% - 140px)',
						margin: '0 0 30px 0',
						borderRadius: theme.borderRadius[3],
						boxShadow: `5px 5px 5px rgba(0,0,0,0.4)`,
					}}
				/>
				{moviePosition < maxPosition ? (
					<NavigationButton onClick={handleForward} direction={'right'} />
				) : (
					<NavigationPlaceholder />
				)}
			</ImageWrapper>
			<Heading align="center" color={theme.colors.accent0}>
				{title}
			</Heading>
			<Paragraph align="center">
				{directedBy}
				{directedBy && releaseDate ? ' | ' : ''}
				{releaseDate?.split('-')[0]}
			</Paragraph>

			<StarRating
				onRatingPicked={handleRating}
				value={reviewRatings[movies[moviePosition]?.id]}
				textColor={brand === 'CINEPLEX' ? '$accentText0' : undefined}
				starActiveColor={
					brand === 'CINEPLEX' ? '$accentText0' : brand === 'CINURU' ? undefined : '$accent0'
				}
				starInactiveColor={brand === 'CINEPLEX' ? '$accentText0' : undefined}
				outline={brand === 'CINEPLEX'}
				starActiveGradient={brand === 'CINURU' ? 'accentGradient1' : undefined}
			/>
			{moviePosition < movies.length ? (
				<Button
					label="nicht gesehen"
					textColor="$accent0"
					color="neutral4"
					align="stretch"
					size="m"
					onPress={() => handleRating(null)}
					link={true}
					outline={true}
				/>
			) : null}
		</AppSection>
	) : movies && moviePosition > movies.length - 1 ? (
		<AppSection height="100vh">
			<Heading align="center" color={'$accent0'}>
				Herzlichen Glückwunsch.
			</Heading>
			<Paragraph>
				Du erhälst in kürze individuell auf dich zugeschnittene Filmempfehlungen von uns.
			</Paragraph>
			<br></br>
			<Paragraph>Viel Spaß im Kino!</Paragraph>
			<Paragraph>Dein {brand[0] + brand.substring(1).toLowerCase()}-Team</Paragraph>
		</AppSection>
	) : (
		<AppSection height="100vh">
			<LoadingIndicator />
		</AppSection>
	);
};

const Quiz = (): JSX.Element => {
	const { brand = 'CINURU' }: { brand?: Brand } = useParams();
	const correctBrands = ['CINURU', 'LUMOS', 'CINEPLEX', 'CINFINITY'];
	const error = correctBrands.includes(brand) ? null : 'WRONG BRAND';

	return error ? (
		<QuizError error={error} />
	) : (
		<ThemeProvider brand={brand} ignoreOsTheme={false}>
			<InnerQuiz />
		</ThemeProvider>
	);
};

export default Quiz;
