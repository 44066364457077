import React from 'react';

import { HeaderSection } from '../Components/HeaderSection';
import { BannerSection } from '../Components/BannerSection';
import { FeatureSection } from '../Components/FeatureSection';
import { PrivacyModal } from '../Components/PrivacyModal';
import { Footer } from '../Components/Footer';
import { usePrivacyModal } from '../utils/helpers';

const App = (): JSX.Element => {
	const [showModal, setCookies] = usePrivacyModal();
	return (
		<>
			{showModal && <PrivacyModal setCookies={setCookies} />}
			<HeaderSection />
			<BannerSection />
			<FeatureSection />
			<Footer />
		</>
	);
};

export default App;
