import React from 'react';
import styled from 'styled-native-components';
import { Button as StoreButton } from './Button';
import { Heading, Button } from '@cinuru/components';

/* ------------------------------- AppSection ------------------------------- */
export const AppSection = styled.View`
	text-align: center;
	height: ${({ height }) => (height ? height : 'auto')};
	padding: 24px 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0;
	> h1 {
		font-weight: 500;
	}
`;

/* ------------------------------ HeaderSection ----------------------------- */

const HeaderLogo = styled.Image`
	width: 20vw;
	height: 20vw;
	min-width: 150px;
	max-width: 300px;
	min-height: 150px;
	max-height: auto;
	margin: 20px 0;
`;

const HeaderStoreButtonRow = styled.View`
	display: flex;
	justify-content: center;
	flex-flow: wrap;
`;

const HeaderStoreButtonImage = styled.View`
	width: 140px;
	height: 33px;
	background-image: url(${(props) => props.image});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`;

export const HeaderSection = (props) => (
	<AppSection>
		<Heading align="center" numberOfLines={1}>
			Bringen Sie Ihr Kino digital weiter
		</Heading>
		<Button
			label="CINURU FÜR KINOBETREIBER"
			href="https://kino.cinuru.com/"
			textColor="$neutral4"
			color="$neutral0"
			cursor="pointer"
		/>

		<HeaderLogo source="../images/logo.png"></HeaderLogo>

		<Heading align="center" numberOfLines={1}>
			Hol dir die kostenlose App und werde Teil deines Kinos.
		</Heading>
		<HeaderStoreButtonRow>
			<StoreButton href={'https://itunes.apple.com/us/app/cinuru/id1281946162?ls=1&mt=8'}>
				<HeaderStoreButtonImage image="../images/apple_store.svg"></HeaderStoreButtonImage>
			</StoreButton>
			<StoreButton
				href={
					'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id%3Dcom.cinuru.app%26ddl%3D1%26pcampaignid%3Dweb_ddl_1'
				}
			>
				<HeaderStoreButtonImage image="../images/play_store.svg"></HeaderStoreButtonImage>
			</StoreButton>
		</HeaderStoreButtonRow>
	</AppSection>
);
