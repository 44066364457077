/* eslint-disable react-perf/jsx-no-new-object-as-prop */

import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, Router } from 'react-router-dom';
import { AppContainer } from 'react-hot-loader';
import { createBrowserHistory } from 'history';

import './App.css';
import createApolloClient from './apollo';
import Template from './Components/Template';
import Quiz from './Routes/Quiz';
import App from './Routes/App';
import Licences from './Routes/Licences';
import VoucherText from './Routes/VoucherText';
import { Bonusterms } from './Routes/Bonusterms';
import { ApolloProvider } from '@apollo/client';

const client = createApolloClient({ cache: true });
const history = createBrowserHistory();

ReactDOM.render(
	<AppContainer>
		<ApolloProvider client={client}>
			<React.StrictMode>
				<Router history={history}>
					<Template>
						<Switch>
							<Route exact path="/" component={App} />
							<Route path="/licences" component={Licences} />
							<Route path="/licenses" component={Licences} />
							<Route path="/bonusterms/:id" component={Bonusterms} />
							<Route path="/thalia-welcome-6ee843fd" component={VoucherText} />
							<Route path="/welcome-coupon" component={VoucherText} />
							<Route path="/welcome-freiticket" component={VoucherText} />
							<Route path="/quiz/:brand?/:sentEmailId?" component={Quiz} />
						</Switch>
					</Template>
				</Router>
			</React.StrictMode>
		</ApolloProvider>
	</AppContainer>,
	document.getElementById('root')
);
