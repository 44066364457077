import * as React from 'react';
import styled from 'styled-native-components';
import { times } from 'lodash';

import Heading from './Heading';
import Label from './Label';
import Icon from './Icon';
import Touchable from './Touchable';

const STAR_MARGINS = [0, 0.25];
const ICON_SIZE = 4.5;
const EXTRA_SPACE = 2;

const Wrapper = styled.View`
	margin: ${(p) => p.margin};
	align-items: center;
	justify-content: center;
`;

const StarsRowWrapper = styled.View`
	height: ${ICON_SIZE}rem;
	flex-direction: row;
	justify-content: center;
`;

const LabelRowWrapper = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: ${5 * (ICON_SIZE + 2 * STAR_MARGINS[1]) + 2 * EXTRA_SPACE}rem;
`;

const LabelWrapper = styled(Touchable)`
	width: ${ICON_SIZE + 2 * STAR_MARGINS[1] + 2 * EXTRA_SPACE}rem;
	align-items: center;
`;

const Star = ({ inactiveColor, activeColor, activeGradient, value, active, outline, onPress }) => {
	const handlePress = React.useCallback(() => {
		onPress && onPress(value);
	}, [onPress, value]);
	return (
		<Icon
			name="star"
			color={active ? activeColor : inactiveColor}
			outline={outline && !active}
			gradient={active && !outline ? activeGradient : undefined}
			size={`${ICON_SIZE}rem`}
			margin={STAR_MARGINS.map((a) => `${a}rem`).join(' ')}
			onPress={handlePress}
		/>
	);
};

const StarLabel = ({ color, value, children, onPress }) => {
	const handlePress = React.useCallback(() => {
		onPress && onPress(value);
	}, [onPress, value]);
	return (
		<LabelWrapper onPress={handlePress}>
			<Label size="xs" color={color} margin="0.5rem 0rem">
				{children}
			</Label>
		</LabelWrapper>
	);
};

const StarRating = ({
	title,
	labels = ['MIES', 'OK', 'TOP'],
	starInactiveColor = '$neutral4',
	starActiveColor,
	starActiveGradient,
	textColor = '$neutral0',
	value,
	onRatingPicked,
	margin = '4rem 0 2rem',
	outline,
}: {
	title?: string;
	labels?: string[];
	value?: number;
	starInactiveColor?: string;
	starActiveColor?: string;
	starActiveGradient?: string;
	textColor?: string;
	onRatingPicked?: (val: number) => unknown;
	margin?: string;
	outline?: boolean;
}) => (
	<Wrapper margin={margin}>
		{title ? (
			<Heading size="xs" color={textColor} align="center" margin="0rem 0rem 1rem">
				{title.toUpperCase()}
			</Heading>
		) : null}
		<StarsRowWrapper>
			{times(5).map((i) => (
				<Star
					key={i}
					active={value && i < value}
					value={i + 1}
					onPress={onRatingPicked}
					activeColor={starActiveColor}
					inactiveColor={starInactiveColor}
					activeGradient={starActiveGradient}
					outline={outline}
				/>
			))}
		</StarsRowWrapper>
		<LabelRowWrapper>
			{labels.map((label, i) => (
				<StarLabel
					key={label}
					value={((5 - 1) / (labels.length - 1)) * i + 1}
					onPress={onRatingPicked}
					color={textColor}
				>
					{label.toUpperCase()}
				</StarLabel>
			))}
		</LabelRowWrapper>
	</Wrapper>
);

export default React.memo(StarRating);
