import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from 'styled-native-components';

import { Brand } from '@cinuru/utils/';
import { back } from '@cinuru/utils/assets/icons.json';

const width = 70;

const Wrapper = styled.div`
	width: ${width};
	height: 150px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	transform: ${(p) => (p.direction === 'right' ? 'rotate(180deg)' : 'rotate(0deg)')};
	:hover path {
		fill: url(#gradientHover);
	}
`;

const Path = styled.path`
	fill: ${(p) => p.fill};
`;

export const Arrow = ({ direction }: { direction: 'right' | 'left' }): JSX.Element => {
	const { brand: BRAND = 'CINURU' }: { brand?: Brand } = useParams();
	const theme = useTheme();
	return (
		<Wrapper direction={direction} brand={BRAND}>
			<svg width={width} height={width} viewBox="0 0 24 24">
				<defs>
					<linearGradient id="gradientHover" x1="0%" y1="0%" x2="100%" y2="0%">
						<stop
							offset="0%"
							stopColor={
								BRAND === 'CINEPLEX'
									? theme.colors.accentText0
									: BRAND === 'CINURU'
									? theme.colors.accentGradient1[0]
									: theme.colors.accentGradient0[0]
							}
						/>
						<stop
							offset="100%"
							stopColor={
								BRAND === 'CINEPLEX'
									? theme.colors.accentText0
									: BRAND === 'CINURU'
									? theme.colors.accentGradient1[1]
									: theme.colors.accentGradient0[1]
							}
						/>
					</linearGradient>
				</defs>
				<Path
					d={back.default}
					stroke={BRAND === 'CINEPLEX' ? theme.colors.accentText0 : 'none'}
					fillRule="evenodd"
					strokeWidth={BRAND === 'CINEPLEX' ? '0.5px' : '0'}
					strokeLinecap="round"
					strokeLinejoin="round"
					brand={BRAND}
					fill={BRAND === 'CINEPLEX' ? 'none' : theme.colors.neutral4}
				/>
			</svg>
		</Wrapper>
	);
};
