import React from 'react';
import styled from 'styled-native-components';

import { Paragraph, Button } from '@cinuru/components';

import { AppSection } from './HeaderSection';

/* ------------------------------ FurtherLinks ------------------------------ */

const Wrapper = styled.View`
	width: 95%;
	max-width: 1600px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	flex-wrap: wrap;
`;

/* --------------------------- SocialMediaSection --------------------------- */
const SocialMediaSection = styled(AppSection)`
	a:hover {
		background-color: white !important;
	}
`;

const ContentWrapper = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	color: white;
	width: 300px;
`;

const LogoWrapper = styled.View`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 130px;
`;

export const Footer = (): JSX.Element => (
	<>
		<AppSection>
			<Wrapper>
				<Button
					label="Impressum"
					href="https://kino.cinuru.com/impressum-b2c"
					textColor="$neutral1"
					cursor="pointer"
					elevation="none"
					link
					linkBackgroundColorActive="false"
				/>
				<Button
					label="Nutzungsbedingungen"
					href="https://kino.cinuru.com/nutzungsbedingungen-b2c"
					textColor="$neutral1"
					link
					cursor="pointer"
				/>
				<Button
					label="Datenschutzerklärung"
					href="https://kino.cinuru.com/datenschutz-b2c"
					textColor="$neutral1"
					link
					cursor="pointer"
				/>
				<Button
					label="Jobs"
					href="https://kino.cinuru.com/jobs"
					textColor="$neutral1"
					link
					cursor="pointer"
				/>
			</Wrapper>
		</AppSection>
		<SocialMediaSection>
			<ContentWrapper>
				<LogoWrapper>
					<img src={'../images/cinuruLogo.svg'}></img>
					<img src={'../images/cinuruName.svg'}></img>
				</LogoWrapper>
				<Button
					label=""
					iconName="email"
					href="mailto:support@cinuru.com"
					textColor="$background0"
					color="$neutral1"
					cursor="pointer"
				/>{' '}
				<Button
					label=""
					iconName="facebook"
					href="https://www.facebook.com/cinuru"
					textColor="$background0"
					color="$neutral1"
					cursor="pointer"
				/>
				<Button
					label=""
					iconName="instagram"
					href="https://www.instagram.com/cinuru/"
					textColor="$background0"
					color="$neutral1"
					cursor="pointer"
				/>
			</ContentWrapper>
		</SocialMediaSection>
		<Paragraph color="$neutral1" align="center">
			© 2021 Cinuru Research GmbH | v3.0.6
		</Paragraph>
	</>
);
