import React from 'react';
import styled from 'styled-native-components';

import { Heading } from '@cinuru/components';

import featureCardInfos from '../JSON/FeatureCardInfo.json';
import { AppSection } from './HeaderSection';
import FeatureCard from './FeatureCard';

/* ----------------------------- FeatureSection ----------------------------- */
const FeatureSectionWrapper = styled(AppSection)`
	padding: 72px 24px;
	background-color: rgb(13, 14, 18);
`;

const FeatureCardWrapper = styled.View`
	max-width: 1600px;
	width: 100%;
	display: inline-flex;
	flex-direction: row;
	gap: 20px;
	justify-content: space-evenly;
	flex-wrap: wrap;
`;

export const FeatureSection = (): JSX.Element => (
	<FeatureSectionWrapper>
		<Heading>DIE FEATURES</Heading>
		<FeatureCardWrapper>
			{featureCardInfos.map(
				({ header, headerColor, description, gradientColors, logoPath }, index) => {
					return (
						<FeatureCard
							key={index}
							gradientId={`feature_gradient_${index}`}
							header={header}
							headerColor={headerColor}
							description={description}
							gradientColors={gradientColors}
							logoPath={logoPath}
						></FeatureCard>
					);
				}
			)}
		</FeatureCardWrapper>
	</FeatureSectionWrapper>
);
