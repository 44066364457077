import React from 'react';

export const usePrivacyModal = (): [boolean, (arg: string[]) => void] => {
	const [showModal, setShowModal] = React.useState(true);

	const evaluateCookies = React.useCallback(() => {
		const cookies = window.document.cookie
			.split('; ')
			.map((entry) => {
				return { [entry.split('=')[0]]: entry.split('=')[1] };
			})
			.reduce((acc, entry) => {
				acc[Object.keys(entry)[0]] = Object.values(entry)[0];
				return acc;
			}, {});

		const consentGiven = cookies.mtm_consent ? true : false;
		const consentRemoved = cookies.mtm_consent_removed ? true : false;
		const consentNecessary = !consentGiven && !consentRemoved ? true : false;
		setShowModal(consentNecessary);
	}, [setShowModal]);

	React.useLayoutEffect(() => evaluateCookies(), [evaluateCookies]);

	const setCookies = React.useCallback(
		(arg: string[]) => {
			window._paq.push(arg);
			evaluateCookies();
			console.log('showModal', showModal);
		},
		[evaluateCookies, showModal]
	);

	return [showModal, setCookies];
};
