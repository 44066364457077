import React from 'react';
import styled from 'styled-components';

const ModalDiv = styled.div`
	font-family: 'Roboto';
	font: 'Roboto';
	width: 30%;
	background-color: #f5f5fa;
	z-index: 1000;
	position: fixed;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 25px;
	margin-bottom: 25px;
	padding: 20px 25px;
	font-size: 0.8rem;
	line-height: 0.9rem;
	@media (max-width: 1000px) {
		width: calc(100% - 100px);
		margin: 0 25px 25px 25px;
	}
`;

const ModalDescription = styled.p`
	text-align: left;
	color: black;
	font-size: 1.4rem;
	line-height: 2rem;
`;

const ModalButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	flex-wrap: wrap;
`;

const ModalButton = styled.button`
	background-color: ${(p) => p.backgroundColor || 'transparent'};
	border-radius: 5px;
	color: ${(p) => p.color || 'black'};
	border-style: none;
	outline: none;
	padding: 5px 10px;
	cursor: pointer;
	margin-left: 12px;
	:hover {
		filter: brightness(110%);
	}
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PrivacyModal = ({
	setCookies,
}: {
	setCookies: (value: string[]) => void;
}): JSX.Element => {
	const forgetConsentGiven = React.useCallback(() => {
		setCookies(['forgetConsentGiven']);
	}, [setCookies]);
	const rememberConsentGiven = React.useCallback(() => {
		setCookies(['rememberConsentGiven']);
	}, [setCookies]);

	return (
		<ModalDiv>
			<ModalDescription>
				Datenschutz ist uns sehr wichtig, daher speichern wir nur Daten, die wir wirklich brauchen.
				Deine Daten werden nicht an externe Services weiter gegeben. Allerdings verwenden wir einen
				Cookie, um dich anonym zu identifizieren, wenn du unsere Website mehrmals besuchst. Auf
				diese Weise können wir die Nutzung der Website besser verstehen. Du kannst dem zustimmen
				oder das ablehnen. Weitere Informationen findest du in unseren Datenschutzerklärungen für{' '}
				<a href="https://kino.cinuru.com/datenschutz-b2c">Endnutzer</a> und{' '}
				<a href="https://kino.cinuru.com/datenschutz">Geschäftskunden</a>.
			</ModalDescription>
			<ModalButtonWrapper>
				<ModalButton onClick={forgetConsentGiven}>Ablehnen</ModalButton>
				<ModalButton color={'white'} backgroundColor={'#1793ff'} onClick={rememberConsentGiven}>
					Akzeptieren
				</ModalButton>
			</ModalButtonWrapper>
		</ModalDiv>
	);
};
