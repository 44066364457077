import React from 'react';
import styled from 'styled-native-components';

import { Heading, Paragraph } from '@cinuru/components';

const Wrapper = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 28%;
	min-width: 300px;
	margin: 100px 25px 0 25px;
`;

const LogoWrapper = styled.View`
	display: grid;
	align-items: center;
	width: 150px;
	height: 150px;
	z-index: 2;
	top: -45%;
	position: absolute;
	align-self: start;
`;

const LogoBackground = styled.View`
	justify-content: center;
	align-items: center;
	grid-column: 1;
	grid-row: 1;
`;

const FeatureInfoWrapper = styled.View`
	z-index: 1;
	width: 100%;
	border-radius: 25px;
	padding: 15px 30px;
	display: flex;
	min-height: 190px;
	justify-content: start;
	flex-direction: column;
	align-items: center;
	background-color: #151518;
	box-shadow: 5px 5px 10px black;
`;

const FeatureCard = ({
	gradientId,
	gradientColors,
	logoPath,
	headerColor,
	header,
	description,
}: {
	gradientId: string;
	gradientColors: { start: string; end: string };
	logoPath: string;
	headerColor: string;
	header: string;
	description: string;
}): JSX.Element => {
	return (
		<Wrapper>
			<LogoWrapper>
				<LogoBackground>
					<svg width="80px" height="80px" style={{ borderRadius: '50%' }}>
						<defs>
							<linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
								<stop offset="0%" stopColor={gradientColors.start} stopOpacity="1"></stop>
								<stop offset="100%" stopColor={gradientColors.end} stopOpacity="1"></stop>
							</linearGradient>
						</defs>
						<rect x="0" y="0" width="100%" height="100%" fill={`url(#${gradientId})`}></rect>
					</svg>
				</LogoBackground>
				<LogoBackground>
					<svg width="55px" height="55px" viewBox="0 0 24 24">
						<path
							fill="#151518"
							stroke="none"
							fillRule="evenodd"
							d={logoPath}
							strokeWidth="0"
							strokeLinecap="round"
							strokeLinejoin="round"
						></path>
					</svg>
				</LogoBackground>
			</LogoWrapper>
			<FeatureInfoWrapper>
				<Heading color={headerColor} margin="30px 0" align="center">
					{header}
				</Heading>
				<Paragraph size="s" align="center" numberOfLines={4}>
					{description}
				</Paragraph>
			</FeatureInfoWrapper>
		</Wrapper>
	);
};

export default FeatureCard;
