import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import cookies from 'js-cookie';

import { API_ENDPOINT } from './consts';

localStorage.removeItem('auth-token');

export default (options: { cache?: boolean } = {}) => {
	const cache = new InMemoryCache();

	const authLink = new ApolloLink((operation, forward) => {
		const csrfToken = cookies.get('_csrf');
		operation.setContext({ headers: { 'CSRF-Token': csrfToken } });
		return forward(operation);
	});

	const authErrorLink = onError(({ graphQLErrors, operation }) => {
		if (graphQLErrors && graphQLErrors.find((e) => e?.extensions?.code === 'UNAUTHENTICATED')) {
			// don't reset store on initial Privileges query, to prevent infinite loop
			if (operation.operationName !== 'Privileges') client.resetStore();
		}
	});

	const errorLogLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors)
			graphQLErrors.map(({ message, locations, path }) =>
				// eslint-disable-next-line no-console
				console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
			);
		// eslint-disable-next-line no-console
		if (networkError) console.log(`[Network error]: ${networkError}`);
	});

	const httpLink = new HttpLink({
		uri: API_ENDPOINT,
		credentials: 'include',
		headers: { brand: 'NONE' },
	});

	const link = ApolloLink.from([authLink, errorLogLink, authErrorLink, httpLink]);

	const client = new ApolloClient({ link, cache });
	return client;
};
