import React from 'react';
import { PrivacyModal } from '../Components/PrivacyModal';

import { Paragraph } from '@cinuru/components';

import { Footer } from '../Components/Footer';
import { usePrivacyModal } from '../utils/helpers';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
	font-size: 16px;
	text-align: left;
	margin: 20px;
	a {
		color: grey;
	}
	a:visited {
		color: grey;
	}
`;

export const Bonusterms = (): JSX.Element => {
	const [showModal, setCookies] = usePrivacyModal();
	const { id }: { id?: string } = useParams();
	const { data, loading } = useQuery(
		gql`
			query BonusProgram($id: ID!) {
				bonusProgram(id: $id) {
					legalEntity
				}
			}
		`,
		{ variables: { id }, skip: !id }
	);
	console.log('Data', data);

	console.log('alive');
	return (
		<Wrapper>
			{showModal && <PrivacyModal setCookies={setCookies} />}
			{loading && <>Wird geladen...</>}
			<h2>Teilnahmebedingungen Cinuru Bonusprogramm </h2>
			<p>{data?.bonusProgram?.legalEntity}</p>
			<p>− im Nachfolgenden “Kino” –</p>
			<p>
				verfügt über ein Bonusprogramm, welches über die App Cinuru der Cinuru Research GmbH
				(nachfolgend die App oder Cinuru) angeboten und durchgeführt wird und an dem du als
				Kinokunde kostenlos teilnehmen kannst. Durch das erstmalige Sammeln von Bonuspunkten
				erklärst du dein Einverständnis mit den nachfolgenden Teilnahmebedingungen. Ein Vertrag über
				die Teilnahme am Bonusprogramm kommt dabei direkt zwischen dir und dem Kino zustande, die
				Cinuru Research GmbH ist hierbei lediglich technischer Dienstleister. Für die Nutzung der
				App finden jedoch die Nutzungsbedingungen der Cinuru Research GmbH Anwendung, auf deren
				Geltung du bei Registrierung in der App hingewiesen wurdest. Für die Teilnahme am
				Bonusprogramm gelten die folgenden Teilnahmebedingungen:
			</p>

			<h4>§ 1 Teilnahmevoraussetzungen </h4>
			<ol>
				<li>
					Am Bonusprogramm kann jede natürliche Person teilnehmen, die ihren Wohnsitz in der
					Bundesrepublik Deutschland hat und das 18. Lebensjahr vollendet hat.
				</li>
				<li>
					Mitarbeiter des Kinos und der Cinuru Research GmbH sind von der Teilnahme am Bonusprogramm
					grundsätzlich ausgeschlossen.
				</li>
				<li>
					Um am Bonusprogramm teilnehmen zu können, benötigst du einen Nutzer-Account für Cinuru.
				</li>
			</ol>

			<h4>§ 2 Bonuspunkte </h4>
			<ol>
				<li>
					Das Bonusprogramm des Kinos ermöglicht dir, in Cinuru Punkte zu sammeln, die beim Kino
					gegen Prämien eingelöst werden können.
				</li>
				<li>
					Beim Bezug von Waren oder Dienstleistungen im Kino, insbesondere beim Kauf von
					Kinotickets, erhältst du Bonuspunkte, indem du beim Erwerb von Tickets an der Kinokasse
					die Cinuru-ID in der App dem Personal vor dem Kauf vorzeigst. Du findest die ID im Tab
					“Mein Kino”. Klicke dazu auf deinen Punktestand und die ID wird als maschinenlesbarer Code
					angezeigt.
				</li>
				<li>
					Punkte können nur vergeben werden, wenn du die entsprechenden Waren oder Dienstleistungen
					bar oder in einer der Barzahlung gleichkommenden Weise (EC-Karte, Kreditkarte) bezahlst,
					also zum Beispiel nicht bei der Einlösung von Freikartengutscheinen, Coupons oder
					Ähnlichem und die Cinuru-ID vor Abschluss des Ticketskaufs vorzeigst. Eine nachträgliche
					Gutschrift der Bonuspunkte ist nicht möglich. Ist eine Buchung von Bonuspunkten aus
					technischen Gründen zum Zeitpunkt des Ticketerwerbs nicht möglich, besteht kein Anspruch
					auf Gutschrift der Bonuspunkte; eine nachträgliche Buchung ist auch in dem Fall nicht
					möglich.
				</li>
				<li>
					Du kannst pro Kinobesuch immer nur für ein Ticket Bonuspunkte sammeln. Deine
					Begleitpersonen benötigen ihren eigenen Account in der App und müssen beim Ticketkauf ihre
					ID ebenfalls vorzeigen, um selbst am Bonusprogramm teilnehmen zu können.
				</li>
				<li>
					Die Höhe der jeweils gewährten Bonuspunkte kannst du in der App einsehen. Das Kino behält
					sich die Vergabe zusätzlicher Bonuspunkte vor, insbesondere im Rahmen von Aktionen.
					Bonuspunkte sind nicht übertragbar und können nicht in Geld umgetauscht werden.
				</li>
				<li>
					Dein aktueller Punktestand ist in Cinuru oder an der Kinokasse einsehbar. Wie dein
					aktueller Punktestand zustande kommt, kannst du in deiner Buchungshistorie in der App
					einsehen.
				</li>
				<li>
					Wird eine Buchung rückabgewickelt, für die Bonuspunkte gutgeschrieben wurden (Umtausch,
					Erstattung, o.Ä.), so behält sich das Kino das Recht vor, auch die Gutschrift deiner
					Bonuspunkte rückgängig zu machen, ebenso bei Fehlbuchungen oder sofern die Voraussetzungen
					für die Gewährung von Bonuspunkten aus anderen Gründen nicht vorlagen, z.B. bei Verstoß
					gegen die Teilnahmebedingungen durch Missbrauch. Ein solcher Missbrauch wird angenommen,
					wenn du deinen Account einem Dritten zur Nutzung überlässt oder bei einer Manipulation der
					App-Inhalte mit dem Zweck der Täuschung. Hast du die Bonuspunkte zu diesem Zeitpunkt
					bereits eingelöst, kann sich daraus unter Umständen auch ein negativer Punktestand
					ergeben.
				</li>
				<li>
					Dem Kino bleibt es vorbehalten, die Gewährung von Bonuspunkten jederzeit zu ändern,
					einzuschränken oder auszuschließen, etwa im Rahmen von Sonderaktionen, bei der Gewährung
					anderer Rabatte oder Vergünstigungen, etc.
				</li>
			</ol>
			<h4>§ 3 Prämien </h4>
			<ol>
				<li>
					Bonuspunkte können beim Kino gegen Prämien eingelöst werden, wenn die für die jeweilige
					Prämie erforderliche Anzahl an Bonuspunkten erreicht wurde
				</li>
				<li>
					Welche Prämien für welche Anzahl von Bonuspunkten erhältlich sind, wird vom Kino bestimmt.
					Eine jeweils aktuell Übersicht kannst du in der App einsehen.
				</li>
				<li>
					Die Einlösung ist ausschließlich an der Kinokasse möglich. Klicke dazu in derApp auf
					“Bonuspunkte einlösen” und tausche die Bonuspunkte gegen einen Gutschein deiner Wahl ein,
					den du dann als maschinell lesbaren Code an der Kinokasse vorzeigst.
				</li>
				<li>
					Prämien können grundsätzlich nur entsprechend ihrer Verfügbarkeit (z.B. nach Zeitraum und
					Anzahl) eingelöst werden.
				</li>
				<li>
					Zuerst erworbene Bonuspunkte werden zuerst eingelöst. Die Bonuspunkte haben eine
					Geltungsdauer von 36 Monaten und verfallen automatisch zum Schluss des Quartals, in dem
					dieser 36-Monats-Zeitraum endet,.
				</li>
				<li>
					Wesentliche Änderungen des Prämienangebots wird das Kino in Textform ankündigen. Sofern du
					das Bonusprogramm weiter nutzt und nicht innerhalb von sechs Wochen in Textform
					widersprichst, gelten die Änderungen als genehmigt.
				</li>
				<li>
					Die Cinuru Research GmbH ist technischer Dienstleister des Kinos und stellt die App Cinuru
					als Plattform bereit. Eine Einlösung von Bonuspunkten gegen Prämien direkt bei der Cinuru
					Research GmbH ist nicht möglich.
				</li>
			</ol>
			<h4>§ 4 Verwendung personenbezogener Daten; gemeinsame Verantworltichkeit </h4>
			<ol>
				<li>
					Das Bonusprogramm wird technisch über Cinuru angeboten und abgewickelt. Dabei werden
					personenbezogene Daten erhoben und durch das Kino und die Cinuru Research GmbH als
					gemeinsame Verantwortliche im Sinne von Art. 26 EU-Datenschutz-Grundverordnung
					verarbeitet.
				</li>
				<li>
					Für alle im Rahmen des Bonusprogamms verarbeiteten personenbezogenen Daten gilt die
					Datenschutzerklärung der Cinuru Research GmbH, auf die du bei der Registrierung in der App
					hingewiesen wurdest. Die Datenschutzerklärung, einschließlich Informationen dazu, welche
					Rechte du in Hinblick auf die Verarbeitung deiner personenbezogenen Daten hast, kannst du{' '}
					<a href="https://kino.cinuru.com/datenschutz-b2c">hier einsehen</a>.
				</li>
			</ol>
			<h4>§ 5 Kündigung </h4>
			<ol>
				<li>
					Du kannst deine Teilnahme am Bonusprogramm jederzeit durch Mitteilung in Textform an
					support@cinuru.com ohne Angabe von Gründen oder Einhaltung von Fristen kündigen.
				</li>
				<li>
					Damit auch deine personenbezogenen Daten bei der Cinuru Research GmbH gelöscht werden, ist
					eine separate Kündigung des Cinuru-Accounts nötig. Bitte schreibe dazu eine E-Mail an:
					support@cinuru.com
				</li>
				<li>
					Bei Löschen deines Cinuru-Accounts wird auch automatisch deine Teilnahme am Bonusprogramm
					des Kinos beendet. Nicht eingelöste Bonuspunkte verfallen.
				</li>
				<li>
					Das Kino kann deine Teilnahme am Bonusprogramm jederzeit unter Setzung einer angemessenen
					Frist (bei Vorliegen eines wichtigen Grunds auch fristlos) kündigen. Ein wichtiger Grund
					zur Kündigung besteht für das Kino insbesondere im Falle eines Missbrauchs des
					Bonusprogramms. Ein solcher wird angenommen, wenn du deinen Account einem Dritten zur
					Nutzung überlässt oder bei einer Manipulation der App-Inhalte mit dem Zweck der Täuschung
					des Kinos.
				</li>
				<li>
					Sofern du zum Zeitpunkt einer ordentlichen Kündigung durch das Kino noch Bonuspunkte auf
					deinem Kundenkonto hast, kannst du diese innerhalb eines Monats noch beim Kino gegen
					Prämien eintauschen. Nicht eingelöste Bonuspunkte verfallen danach.
				</li>
			</ol>

			<h4>§ 6 Änderungen der Teilnahmebedingungen und Beendigung des Bonusprogramms </h4>
			<ol>
				<li>
					Das Kino behält sich vor, die Teilnahmebedingungen an dem Bonusprogramm unter Wahrung
					deiner berechtigten Interessen zu ändern. In diesem Fall finden die neuen
					Teilnahmebedingungen jedoch erst Anwendung, wenn das Kino dich von den Änderungen
					schriftlich in Kenntnis gesetzt hat und du nicht innerhalb eines Monats nach Zugang der
					Änderungsmitteilung bei dem Kino Widerspruch erhebst. Das Kino wird dich auf diese
					Rechtsfolge in der Änderungsmitteilung ausdrücklich hinweisen. Akzeptierst du die neuen
					Teilnahmebedingungen gemäß dem Vorstehenden nicht, gilt dies als Kündigung des Vertrages
					über die Teilnahme an dem Bonusprogramm durch dich.
				</li>
				<li>
					Das Kino behält sich außerdem vor, sein Bonusprogramm jederzeit unter Einhaltung einer
					angemessenen Frist (bei Vorliegen eines wichtigen Grunds auch fristlos) und unter Wahrung
					deiner berechtigten Interessen zu beenden. In diesem Fall bist du berechtigt, gesammelte
					Bonuspunkte noch innerhalb eines Monats nach dem Ende des Bonusprogramms einzulösen, es
					sei denn die Beendigung ist aus wichtigem Grund erfolgt. Nicht eingelöste Bonuspunkte
					verfallen im Anschluss.
				</li>
			</ol>
			<h4>§ 7 Haftung </h4>
			<ol>
				<li>
					Das Kino haftet nach den folgenden Bestimmungen für Schäden, die dir durch das Kino oder
					seine Erfüllungsgehilfen entstehen nach Maßgabe dieses § 7.
				</li>
				<li>
					Bei Vorsatz oder grober Fahrlässigkeit haftet das Kino der Höhe nach unbeschränkt nach den
					gesetzlichen Vorschriften.
				</li>
				<li>
					Bei leicht fahrlässiger Verletzung einer wesentlichen Vertragspflicht ist die Haftung des
					Kinos auf den typischerweise eintretenden vorhersehbaren Schaden beschränkt, soweit nicht
					eine Verletzung von Gesundheit, Körper oder Leben vorliegt. In letzterem Fall haftet das
					Kino ebenfalls der Höhe nach unbeschränkt. Eine wesentliche Vertragspflicht ist jede
					Pflicht, deren Erfüllung die Durchführung des Vertrags überhaupt erst ermöglicht und auf
					deren Einhaltung du als Teilnehmer regelmäßig vertrauen darfst.
				</li>
				<li> Jede weitergehende Haftung auf Schadensersatz des Kinos ist ausgeschlossen.</li>
			</ol>
			<h4>§ 8 Schlussbestimmungen </h4>
			<ol>
				<li>
					Auf das Vertragsverhältnis zwischen dir und dem Kino findet das deutsche Recht unter
					Ausschluss des internationalen Privatrechts Anwendung.
				</li>
				<li>
					Sollten einzelne Regelungen der vorstehenden Teilnahmebedingungen ganz oder teilweise
					unwirksam oder nichtig sein oder werden, so berührt dies die übrigen Bestimmungen nicht.
				</li>
			</ol>

			<Footer />
		</Wrapper>
	);
};
