import React from 'react';
import { PrivacyModal } from '../Components/PrivacyModal';

import { Paragraph } from '@cinuru/components';

import { Footer } from '../Components/Footer';
import { LICENCES } from '../licencesData';
import { AppSection } from '../Components/HeaderSection';
import { usePrivacyModal } from '../utils/helpers';

const Licences = (): JSX.Element => {
	const [showModal, setCookies] = usePrivacyModal();

	return (
		<>
			{showModal && <PrivacyModal setCookies={setCookies} />}
			<AppSection>
				<Paragraph>{LICENCES}</Paragraph>
			</AppSection>
			<Footer />
		</>
	);
};

export default Licences;
