import React from 'react';
import styled from 'styled-components';
import { Arrow } from './Arrow';

const StyledButton = styled.button`
	background-color: transparent;
	outline: none;
	color: none;
	border: none;
	cursor: pointer;
	padding: 0;
`;

const NavigationButton = ({
	onClick,
	direction,
}: {
	onClick: () => void;
	direction: 'right' | 'left';
}): JSX.Element => {
	return (
		<StyledButton onClick={onClick}>
			<Arrow direction={direction} />
		</StyledButton>
	);
};

export default React.memo(NavigationButton);
