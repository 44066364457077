import React from 'react';
import { PrivacyModal } from '../Components/PrivacyModal';

import { Paragraph, Heading } from '@cinuru/components';

import { Footer } from '../Components/Footer';
import { AppSection } from '../Components/HeaderSection';
import { usePrivacyModal } from '../utils/helpers';

const VoucherText = (): JSX.Element => {
	const [showModal, setCookies] = usePrivacyModal();

	return (
		<>
			{showModal && <PrivacyModal setCookies={setCookies} />}
			<AppSection>
				<Heading align="center">{'So sammelst du Punkte:'}</Heading>
				<br />
				<br />
				<div
					style={{
						backgroundColor: '#CACACB',
						borderRadius: '15px',
						padding: '3rem',
					}}
				>
					<Paragraph align="center" color="black">
						<br />
						1. Hol dir unsere kostenlose App bei Google Play oder im App Store.
						<br />
						<br />
						2. Lege dir einen Account an.
						<br />
						<br />
						3. Klicke auf „Gutschein scannen“ und lies den QR-Code ein.
						<br />
						<br />
					</Paragraph>
				</div>
				<br />
				<br />
				<br />
				<br />
				<Paragraph align="center">
					Sammle bei jedem Kinobesuch weitere Punkte und erhalte tolle Prämien. <br /> Einfach die
					App beim Ticketkauf an der Kasse vorzeigen.
				</Paragraph>
			</AppSection>
			<Footer />
		</>
	);
};

export default VoucherText;
