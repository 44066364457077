import React from 'react';
import styled from 'styled-components';

import '../App.css';
import ThemeProvider from './ThemeProvider';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	background-color: #151518;
	width: 100%;
`;

const Workspace = styled.div`
	text-align: center;
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: top;
	width: 100%;
`;

const Template = ({ children }: { children: React.ReactNode }): JSX.Element => {
	return (
		<ThemeProvider ignoreOsTheme={true}>
			<Wrapper>
				<Workspace>{children}</Workspace>
			</Wrapper>
		</ThemeProvider>
	);
};

export default Template;
