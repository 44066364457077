import React from 'react';
import styled from 'styled-components';

import { Heading, Paragraph } from '@cinuru/components';

import { AppSection } from './HeaderSection';

const StyledLink = styled.a`
	text-decoration: underline;
	cursor: pointer;
	color: cornflowerblue;
`;

export const QuizError = ({ error }: { error: string | null }): JSX.Element => {
	return (
		<AppSection height="100vh">
			<Heading align="center" color="$accent0">
				UPS! Ein Fehler ist aufgetreten.
			</Heading>
			<Paragraph align="center">
				Bitte schick uns eine Email an{' '}
				<StyledLink href="mailto: bugs@cinuru.com">bugs@cinuru.com</StyledLink> mit dem Fehlercode:{' '}
				{error}
			</Paragraph>
		</AppSection>
	);
};
